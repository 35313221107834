/** Note: This file contains all the API URLS and All URLS are must be start without "/" and must be end with "/" */

export const apiUrlChatbot = {
  getIntents: "project/{0}/chatbot/{1}/intent/",
  getIntentTags: "project/{0}/chatbot/{1}/intent-tag/",
  getIntentRecords: "project/{0}/intent-record/",
  getIntentRecordsV2: "project/{0}/intent-recordv2/",
  getEntityType: "project/{0}/chatbot/{1}/entity-type/",
  getIntentRecordSampleIntent: "project/{0}/chatbot/{1}/intent/{2}/sample-intent/",
  getEntityTypeImportExport: "project/{0}/chatbot/{1}/entity-type/{2}/import-export",
  getDictionary: "project/{0}/chatbot/{1}/dictionary/",
  getBotMessage: "project/{0}/chatbot/{1}/bot-message/",
  getVariable: "project/{0}/chatbot/{1}/variable/",
  getMedia: "project/{0}/chatbot/{1}/media/",
  getDatabase: "project/{0}/chatbot/{1}/database/",
  getProcedure: "project/{0}/chatbot/{1}/procedure/",
  getProcedureVersion: "project/{0}/chatbot/{1}/procedure/{2}/version/{3}/",
  getDebugSession: "project/{0}/chatbot/{1}/debug-session",
  getIntentPrediction: "project/{0}/chatbot/{1}/intent-prediction",
  getIntentSample: "project/{0}/chatbot/{1}/intent-sample",
  getIntentResponse: "project/{0}/chatbot/{1}/intent-response",
  getClusterModelViewSet: "project/{0}/chatbot/{1}/cluster/",
  getRecommendationGenerator: "project/{0}/chatbot/{1}/recommendation-generator/",
  // updateClusters: "project/{0}/chatbot/{1}/update_clusters",
  updateRecommendations: "project/{0}/chatbot/{1}/trigger-intent-recommendation",
  getInsight: "project/{0}/chatbot/{1}/2D-insight",
  // RAG
  getRagFactGroup: "project/{0}/rag/fact-group/{1}/",
  postRagMessage: "project/{0}/rag/fact-group/{1}/test/",
  processRag: "project/{0}/rag/fact-group/{1}/process/",
  stopRag: "project/{0}/rag/fact-group/{1}/stop/",
  ragFile2Text: "project/{0}/rag/file2text",
};

export const apiUrlECommerce = {
  get: "project/{0}/product/platform/",
  testIntegration: "project/{0}/product/test-integration/{1}/",
};
export const apiUrlTicket = {
  file: "ticket/file/", //TODO: implement this
  getPublic: "ticket/public/{0}/view/",
  postPublicComment: "ticket/public/{0}/comment/",
  postPublicNote: "ticket/public/{0}/note/",
  postPublicApprove: "ticket/public/{0}/approve/",

  //TODO: remove non-alias endpoints after deployment
  fileAlias: "{0}/ticket/file/", //TODO: implement this
  getPublicAlias: "{0}/ticket/public/{1}/view/",
  postPublicCommentAlias: "{0}/ticket/public/{1}/comment/",
  postPublicNoteAlias: "{0}/ticket/public/{1}/note/",
  postPublicApproveAlias: "{0}/ticket/public/{1}/approve/",

  get: "project/{0}/ticket/{1}/",
  post: "project/{0}/ticket/",
  getList: "project/{0}/ticket/",
  postComment: "project/{0}/ticket/{1}/comment/",
  postNote: "project/{0}/ticket/{1}/note/",
  getShareTicket: "project/{0}/ticket/{1}/share/",

  // postComment: "project/{0}/ticket/ticket-comment-note/{1}/",
  // getShareToken: "project/{0}/ticket/share-ticket/{1}/",
  getCategory: "project/{0}/ticket-category/",
};

export const apiUrlTicketCategory = {
  get: "project/{0}/ticket-category/",
};

export const apiUrlPermission = {
  get: "/project/{0}/permissions/",
};
export const apiUrlInvitation = {
  get: "/project/{0}/invitation/",
};

export const apiUrlInviteUser = {
  get: "project/{0}/invite-user/",
};

export const apiUrlDefaultMessage = {
  get: "project/{0}/default-messages/",
};

export const apiUrlChatLabels = {
  get: "project/{0}/chat-label/",
  set: "project/{0}/set-label/{1}/",
};
export const apiUrl = {
  logout: "logout/",
};

export const apiUrlNotification = {
  get: "notifications/",
  getDevice: "callcenter/device/",
  createDevice: "callcenter/device/",
};
export const apiUrlChannel = {
  get: "project/{0}/platforms/integration-credential/{0}/",
  getList: "project/{0}/platforms/integration-credential/",
};

export const apiUrlOrganization = {
  getAnnouncement: "project/{0}/organization/announcement/",
  getReservationAnnouncement: "project/{0}/organization/reservation-announcement/",
  getAnnouncementVersion: "project/{0}/organization/announcement/{1}/version/",
  getAnnouncementVersionMembers: "project/{0}/organization/announcement/{1}/version/{2}/member/",
  getMember: "project/{0}/organization/member/",
  getMemberDataFormat: "project/{0}/organization/member-data-format/",
  getFilter: "project/{0}/organization/filter/",
  getAction: "project/{0}/organization/action/",
  getSurvey: "project/{0}/organization/survey/",
  getSurveyResult: "project/{0}/organization/survey/{1}/results/",
  getAnnounce: "project/{0}/organization/survey/{1}/announce/",
  getReAnnounce: "project/{0}/organization/survey/{1}/re-announce/",
  getResource: "project/{0}/organization/resource/",
  getResourceManagerList: "project/{0}/organization/resource-managers/",
  updateResourceBlacklist: "project/{0}/organization/resource/{1}/blacklist/",
  getDynamicReservation: "project/{0}/organization/resource/{1}/dynamic-reservation/",
  getReservationActions: "project/{0}/organization/resource/{1}/reservation-action/",
  getReservationMembers: "project/{0}/organization/slot/member/",
  getReservationGenerator: "project/{0}/organization/resource/{1}/reservation-generator/",
  getMakeAnnouncement: "project/{0}/organization/make-announcement/",
  getCancelAnnouncement: "project/{0}/organization/cancel-announcement/",
  getResourceAddBulkSlots: "project/{0}/organization/resource/{1}/add-slot/",
  getResourceAddSlot: "project/{0}/organization/resource/{1}/slot/",
  getResourceDetailed: "project/{0}/organization/resource/{1}/slot/{2}/detailed/",
  getReservation: "project/{0}/organization/resource/{1}/reservation/",
  // getSecret: "project/{0}/organization/generate-key/",
  getLoadMemberData: "project/{0}/organization/member-data/",
  getSecretKey: "project/{0}/organization/secret-key/",
};

export const apiUrlProject = {
  get: "project/",
  getTemplates: "project-templates/",
  getLanguages: "language/",
  getFiles: "project/{0}/project-file/",
};
export const apiUrlCompany = {
  get: "company/",
};

export const apiUrlProjectSettings = {
  get: "project/{0}/project-settings/{1}/",
};

export const apiUrlToken = {
  get: "token/",
  getRefresh: "token/refresh/",
};

export const apiUrlRegister = {
  get: "user-register/",
};

export const apiUrlCompanyRegister = {
  get: "company-register/",
};
export const apiUrlResetPassword = {
  get: "reset-password/",
};
export const apiUrlSendResetPassword = {
  get: "reset-password2/",
};
export const apiUrlNewPassword = {
  get: "new-password/",
};
export const apiUrlJoinProject = {
  get: "join-project/",
};
export const apiUrlUser = {
  get: "user/",
  updatePassword: "update-password/",
  update2FAStatus: "enable_2fa/",
  validate2FA: "validate_2fa/",
};
export const apiUrlInvitationInfo = {
  get: "invitation-info/",
};
export const apiUrlCheckUsername = {
  get: "check-username/",
};

export const apiUrlMessageTemplate = {
  get: "project/{0}/template-message/",
  getFile: "project/{0}/template-message/{1}/file/",
  getType: "project/{0}/template-message-types/",
  postTestMessage: "project/{0}/template-message-test/",
};

// export const apiUrlMessageTemplateRegistry = {
//   get: "project/{0}/template-data/",
// };

export const apiUrlIntegrationModule = {
  get: "project/{0}/integration/module/",
  newVersion: "project/{0}/integration/module/{1}/new-version/",
  integrate: "project/{0}/integration/module/{1}/integrate/{2}/",
  registry: "project/{0}/integration/module-registry/{1}/",
};

export const apiUrlIntegrationModuleVersion = {
  get: "project/{0}/integration/module-version/",
};

export const apiUrlIntegrationTriggerList = {
  get: "project/integration/trigger-list/",
};

export const apiUrlIntegrationModuleRegistry = {
  get: "project/{0}/integration/module-registry/",
};

export const apiUrlStatistics = {
  getWeekly: "project/{0}/customer/weekly-sessions/",
  getWeeklyTickets: "project/{0}/ticket/weekly-tickets/",
  getChatbot: "project/{0}/chatbot/{1}/statistics",
  getAgent: "project/{0}/callcenter/agent-statistics/{1}/",
  getAgentTicket: "project/{0}/ticket/agent-ticket-statistics/{1}/",
  getTicketStatistics: "project/{0}/ticket-statistics/",
  getGenericStatistics: "project/{0}/statistics/get-statistics/",
  getStatistics: "project/{0}/statistics/info/",
  getPublicStatistics: "statistics-report/{0}/",
};

export const apiUrlGuide = {
  get: "/page-guide/",
};

export const apiUrlAgentList = {
  get: "project/{0}/callcenter/agent/{1}/",
  list: "project/{0}/callcenter/agent/",
};

export const apiUrlAgentLeaderboard = {
  get: "project/{0}/statistics/leaderboard/{1}/",
  list: "project/{0}/statistics/leaderboard/",
};

export const apiUrlIntentLeaderboard = {
  list: "project/{0}/statistics/intent-leaderboard/",
  listTag: "project/{0}/statistics/intent-tag-leaderboard/",
};
export const apiUrlLivechat = {
  upload: "/callcenter/new-file/",
  callcenterSettings: "project/{0}/callcenter/settings/",
};

export const apiUrlRag = {
  llmSettings: "/project/{0}/rag/llm/{1}/",
};

export const apiUrlWhatsappTemplate = {
  getList: "project/{0}/platforms/whatsapp/template/",
  get: "project/{0}/platforms/whatsapp/template/{1}/",
  send: "project/{0}/platforms/whatsapp/template/{1}/send",
  sync: "project/{0}/platforms/whatsapp/template/sync/",
};

export const apiUrlKeyStore = {
  get: "public-key-store/",
};

export const apiUrlWebchat = {
  newSession: "customer/new-session/",
  welcomeForm: "project_model/welcome_form/",
  newFile: "customer/new-file/",
  websocket: "ws/customer/",
  sendHistory: "customer/send-history/",

  newSessionAlias: "{0}/customer/new-session/",
  welcomeFormAlias: "{0}/customer/welcome_form/",
  newFileAlias: "{0}/customer/new-file/",
  websocketAlias: "{0}/ws/customer/",
};

export const apiUrlLiveChatHistory = {
  get: "customer/all-sessions2",
};

export const apiUrlAppVersion = {
  get: "app-version/",
};

export const apiUrlAppAnnouncement = {
  get: "app-announcement/",
};

export const apiUrlStatisticGenerator = {
  get: "project/{0}/statistics/statistics-generator/",
  getReports: "project/{0}/statistics/generator/{1}/statistics-report/",
  downloadReport: "project/{0}/statistics/statistics-report/{1}/download/",
  generateAsync: "project/{0}/statistics/generator/{1}/generate-async/",
  generate: "project/{0}/statistics/generator/{1}/generate/",
};

export const apiUrlReservationGenerator = {
  get: "project/{0}/organization/reservation-generator/",
  generate: "project/{0}/organization/reservation-generator/",
};

export const apiUrlGetterSetter = {
  get: "livechat/project/{0}/session/{1}/",
};

export const apiUrlLowcode = {
  getComplete: "project/{0}/lowcode/complete/",
  getDocumentation: "project/{0}/lowcode/documentation/",
};

export const apiUrlOnboarding = {
  getWebpage: "project/{0}/rag/source-webpage/",
  getWebpageDetail: "project/{0}/rag/source-webpage/{1}/",
  getFile: "project/{0}/rag/source-file/",
  getFileDetail: "project/{0}/rag/source-file/{1}/",
  getChatbot: "project/{0}/chatbot/{1}/",
  getOnboarding: "onboarding/",
  getWelcomeIntentConfig: "project/{0}/chatbot/{1}/welcome-intent-config",
  getFailIntentConfig: "project/{0}/chatbot/{1}/fail-intent-config",
  getRagIntentConfig: "project/{0}/chatbot/{1}/rag-intent-config",
  getIntegration: "project/{0}/platforms/integrations",
  postInstagramOnboarding: "project/{0}/platforms/instagram-onboarding/",
  postWhatsappOnboarding: "project/{0}/platforms/whatsapp-onboarding/",
};
