import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { ReactComponent as InstagramIcon } from "~assets/images/icons/instagram.svg";
import { ReactComponent as TelegramIcon } from "~assets/images/icons/telegram.svg";
import { ReactComponent as WhatsAppIcon } from "~assets/images/icons/whatsapp.svg";
import IntegrationLoadingModal from "~common/modals/IntegrationLoadingModal";
import PRContainer from "~components/Generic/PRContainer";
import { IntegrationDetailModal, IntegrationItem, ManualIntegrationModal } from "~pages/Onboarding";
import { updateInstagramOnboarding, updateWhatsappOnboarding } from "~store/onboarding/actions";
import { createOrUpdateChannel, getChannelList } from "~store/settings/channels/actions";
import { selectChannelList } from "~store/settings/channels/selectors";
import { selectCurrentProject } from "~store/user/selectors";

import "./style.scss";

// const addPlusPrefix = (value) => (value ? (value.startsWith("+") ? value : `+${value}`) : value);

export default function Channels() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const channelList = useSelector(selectChannelList);

  const [authState, setAuthState] = useState({
    whatsapp: false,
    Instagram: false,
  });

  useEffect(() => {
    dispatch(getChannelList(currentProject.id));
  }, [dispatch, currentProject]);

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (document.getElementById("facebook-jssdk")) return;

      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;

      script.onload = () => {
        initializeFacebookSDK();
      };

      document.body.appendChild(script);
    };

    const initializeFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: import.meta.env.VITE_APP_FACEBOOK_APP_ID || "1427714218189046",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v16.0",
        });
      };
    };

    loadFacebookSDK();
  }, []);

  const { whatsappData, telegramData, instagramData } = useMemo(() => {
    const whatsappData = channelList.find((item) => item.integration_type === "whatsapp");
    const telegramData = channelList.find((item) => item.integration_type === "telegram");
    const instagramData = channelList.find((item) => item.integration_type === "instagram");
    return { whatsappData, telegramData, instagramData };
  }, [channelList]);

  const startWhatsappSignupFlow = async () => {
    if (!window.FB) {
      console.error("Facebook SDK not loaded yet.");
      return;
    }

    setAuthState({ ...authState, whatsapp: true });
    IntegrationLoadingModal.show({ permanent: true }).then(() => {
      setAuthState({ ...authState, whatsapp: false });
    });

    const fbPromise = new Promise((resolve) => {
      const tempCallback = (event) => {
        if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
          return;
        }
        try {
          const data = JSON.parse(event.data);
          if (data.type === "WA_EMBEDDED_SIGNUP") {
            if (data.event === "FINISH") {
              const { phone_number_id, waba_id } = data.data;
              window.removeEventListener("message", tempCallback);
              resolve({ phone_number_id, waba_id });
            } else if (data.event === "CANCEL") {
              // const { current_step } = data.data;
              // console.warn("Cancel at ", current_step);
              window.removeEventListener("message", tempCallback);
              resolve(null);
            } else if (data.event === "ERROR") {
              const { error_message } = data.data;
              console.error("error ", error_message);
              window.removeEventListener("message", tempCallback);
              resolve(null);
            }
          }
        } catch {}
      };
      window.addEventListener("message", tempCallback);
    });

    const loginPromise = new Promise((resolve) => {
      window.FB.login(
        function (response) {
          // console.log("Whatsapp login response", response);
          setAuthState({ ...authState, whatsapp: false });
          IntegrationLoadingModal.hide();
          if (response.authResponse) {
            resolve(response.authResponse.code);
          } else {
            console.error("Whatsapp login failed.");
            resolve(null);
          }
        },
        {
          config_id: "1101788468095291", // configuration ID goes here
          response_type: "code", // must be set to 'code' for System User access token
          override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
          extras: {
            setup: {},
            featureType: "",
            sessionInfoVersion: "2",
          },
        }
      );
    });

    await Promise.all([fbPromise, loginPromise]).then(([fbData, accessToken]) => {
      const { phone_number_id, waba_id } = fbData;
      dispatch(
        updateWhatsappOnboarding(currentProject?.id, {
          code: accessToken,
          phone_number_id,
          waba_id,
        })
      );
    });
    await dispatch(getChannelList(currentProject.id));
  };

  const startInstagramLogin = () => {
    if (window.INS_POPUP) {
      window.INS_POPUP.focus();
      return;
    }
    const clientId = import.meta.env.VITE_APP_INSTAGRAM_CLIENT_ID || "1848589722341734";
    const redirectUri = window.location.origin + import.meta.env.VITE_APP_PUBLIC_URL + "/instagram-callback/";
    const scope = "instagram_business_basic";
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=${encodeURIComponent(scope)}`;

    const popupWidth = 600;
    const popupHeight = 700;
    const left = window.screenX + (window.innerWidth - popupWidth) / 2;
    const top = window.screenY + (window.innerHeight - popupHeight) / 2;

    IntegrationLoadingModal.show().then(() => {
      setAuthState({ ...authState, Instagram: false });
      if (window.INS_POPUP && !window.INS_POPUP.closed) {
        window.INS_POPUP.close();
      }
    });
    setAuthState({ ...authState, Instagram: true });
    window.INS_POPUP = window.open(
      authUrl,
      "InstagramLogin",
      `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
    );

    const popupEventListener = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data?.code) {
        window.removeEventListener("message", popupEventListener);
        IntegrationLoadingModal.hide();
        // setIntegrationState({ ...integrationState, Instagram: event.data.code });
        await dispatch(
          updateInstagramOnboarding(currentProject?.id, {
            code: event.data.code,
          })
        );
        await dispatch(getChannelList(currentProject.id));
      }
    };
    window.addEventListener("message", popupEventListener);

    const interval = setInterval(() => {
      if (!window.INS_POPUP || window.INS_POPUP.closed) {
        clearInterval(interval);
        setAuthState({ ...authState, Instagram: false });
        IntegrationLoadingModal.hide();
        window.INS_POPUP = null;
      }
    }, 500);
  };

  const handleClickShowDetails = (integration) => () => {
    IntegrationDetailModal.show({ integration });
  };

  const handleManualIntegration = (integrationName) => async () => {
    let inputFields = [];
    let state = {};
    if (integrationName === "telegram") {
      state = telegramData || {};
      inputFields = [
        {
          label: "Name",
          key: "name",
          required: true,
        },
        {
          label: "App ID",
          key: "app_id",
          required: true,
        },
        {
          label: "Access Token",
          key: "access_token",
          required: true,
        },
        {
          label: "Secret",
          key: "secret",
          required: true,
        },
      ];
    }

    const responseState = await ManualIntegrationModal.show({ integrationName, inputFields, state });
    if (responseState) {
      const newState = {
        id: state.id,
        ...responseState,
      };
      if (newState?.access_token && newState?.access_token === state?.access_token) {
        delete newState.access_token;
      }
      if (newState?.secret && newState?.secret === state?.secret) {
        delete newState.secret;
      }
      await dispatch(createOrUpdateChannel(currentProject.id, newState));
      await dispatch(getChannelList(currentProject.id));
    }
  };

  return (
    <PRContainer
      className="pr-channels"
      description={t("dashboard.channels.description")}
      name={t("common.settings")}
      parentName={t("dashboard.channels")}
    >
      <Grid container spacing={2}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <IntegrationItem
            desc="Connect your Instagram account to chat with your customers."
            image={InstagramIcon}
            isDone={instagramData?.access_token}
            name="Instagram"
            onClick={startInstagramLogin}
            onClickDetail={handleClickShowDetails(instagramData)}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <IntegrationItem
            desc="Connect your WhatsApp business account to communicate with your customers from your business number."
            image={WhatsAppIcon}
            isDone={whatsappData?.access_token}
            name="WhatsApp"
            onClick={startWhatsappSignupFlow}
            onClickDetail={handleClickShowDetails(whatsappData)}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <IntegrationItem
            desc="Connect your Telegram account to chat with your customers."
            image={TelegramIcon}
            isDone={telegramData?.access_token}
            onClickConfigure={handleManualIntegration("telegram")}
            name="Telegram"
            // onClick={handleManualIntegration("telegram")}
            onClickDetail={handleClickShowDetails(telegramData)}
          />
        </Grid>
      </Grid>
    </PRContainer>
  );
}
